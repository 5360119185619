<template>
    <b-card>
      <b-card-body>
        <profile-info network="twitch" :userData="twitchData"></profile-info>
      </b-card-body>
      <b-card-body>
        <b-row class="justify-content-between">
          <b-col class="d-flex justify-content-start align-items-center mb-1">
            <b-avatar size="30px" rounded class="mr-1 bg-primary bg-lighten-2">
              <feather-icon size="15" :icon="'UserIcon'"></feather-icon>
            </b-avatar>
            <div class="profile-user-info">
              <h4 class="mb-0">{{ subs }}</h4>
              <b-card-text class="text-muted">{{$t('campaigns.followers')}}</b-card-text>
            </div>
          </b-col>
          <b-col class="d-flex justify-content-start align-items-center mb-1">
            <b-avatar size="30px" rounded class="mr-1 bg-success bg-lighten-2">
              <feather-icon size="15" :icon="'EyeIcon'"></feather-icon>
            </b-avatar>
            <div class="profile-user-info">
              <h4 class="mb-0">{{ viewsFormat }}</h4>
              <b-card-text class="text-muted">{{$t('youtube.views')}}</b-card-text>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
</template>
<script>
import {
  BCard, BCardBody, BRow, BCol, BCardText, BAvatar,
} from 'bootstrap-vue'
import utils from '@/libs/utils'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    ProfileInfo: () => import('../ProfileInfo.vue')
  },
  props: {
    twitchData: {
      type: Object,
      required: true
    },
    views: {
      type: Object,
      required: true
    },
    subscribers: {
      type: Object,
      required: true
    }
  },
  computed: {
    subs() {
      return utils.getFormat(this.subscribers.value)
    },
    viewsFormat() {
      return utils.getFormat(this.views.value)
    }
  }
}
</script>
